<template>
  <v-col cols="12">
    <Loader
      v-if="loading"
      :key="generateUniqueId()"
      :loader-state="loading"
    ></Loader>

    <v-card>
      <v-card-title>Slides {{ $route.params.locale.toUpperCase() }}</v-card-title>

      <v-card-text>
        <div class="global-actions">
          <router-link :to="{ name: 'slideCreate' }">
            <v-btn
              small
              color="primary"
              class="mt-5"
            >
              Ajouter un slide
            </v-btn>
          </router-link>
        </div>
      </v-card-text>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-uppercase">
                Titre
              </th>
              <th class="text-center text-uppercase">
                Position
              </th>
              <th class="text-center text-uppercase">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(slide, slideIndex) in slides"
              :key="'c'+ slideIndex"
            >
              <td class="font-weight-bold">
                {{ slide.title }}
              </td>
              <td class="text-center">
                {{ slide.position }}
              </td>
              <td class="text-center">
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      color="warning"
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      :to="{ name: 'slideDetails', params: { slideId: slide.id }}"
                    >
                      <v-icon dark>
                        {{ icons.mdiPen }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Consulter</span>
                </v-tooltip>

                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      class="ml-2"
                      color="error"
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      @click="openDeleteSlideDialog(slide)"
                    >
                      <v-icon dark>
                        {{ icons.mdiTrashCan }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Supprimer</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <DeleteSlideDialog
        :key="Date.now()"
        :delete-slide-dialog-state="deleteSlideDialogState"
        :slide="slideToDelete"
        @closeDeleteSlideDialog="closeDeleteSlideDialog($event)"
      ></DeleteSlideDialog>
    </v-card>
  </v-col>
</template>

<script>
import { mdiPen, mdiBackupRestore, mdiDatabaseSearchOutline, mdiTrashCan } from '@mdi/js'
import Vue from 'vue'
import config from '../../../config'
import Loader from '@/components/Common/Loader'
import DeleteSlideDialog from '@/components/Dialog/SlideDialog/DeleteSlideDialog'

export default {
  components: { DeleteSlideDialog, Loader },
  data: () => ({
    loading: false,
    slides: [],
    nextPage: 1,
    slideToDelete: null,
    deleteSlideDialogState: false,
    icons: {
      mdiPen,
      mdiBackupRestore,
      mdiDatabaseSearchOutline,
      mdiTrashCan
    }
  }),
  computed: {
    isHandlingSlideScroll () {
      return this.$store.getters.isHandlingSlideScroll
    }
  },
  watch: {
    $route: function () {
      this.slides = []
      this.nextPage = 1
      this.getSlides()
    }
  },
  created () {
    this.getSlides()

    window.removeEventListener('scroll', this.handleScroll)

    setTimeout(() => {
      window.addEventListener('scroll', this.handleScroll)
    }, 1000)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      const wrapperOffsetHeight = document.querySelector('.v-application--wrap').offsetHeight
      const bottomOfWindow = (document.documentElement.scrollTop + window.innerHeight) === wrapperOffsetHeight

      if (bottomOfWindow && !this.isHandlingSlideScroll) {
        this.getSlides()
      }
    },
    getSlides () {
      this.loading = true

      const params = {
        page: this.nextPage,
        locale: this.$route.params.locale
      }

      this.$store.commit('setHandlingSlideScroll', true)

      Vue.prototype.$http
        .get(config.apiUrl + '/api/slides', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: params
        })
        .then(response => {
          if (response.status === 200) {
            const slides = response.data

            if (slides && slides.length) {
              this.slides.push(...slides)

              this.nextPage = this.nextPage + 1
            }
          }

          this.loading = false
          this.$store.commit('setHandlingSlideScroll', false)
        })
        .catch(() => {
          this.loading = false
          this.$store.commit('setHandlingSlideScroll', false)
        })
    },
    openDeleteSlideDialog (slide) {
      this.deleteSlideDialogState = true
      this.slideToDelete = slide
    },
    closeDeleteSlideDialog (deleteState) {
      if (deleteState === true) {
        this.slides = this.slides.filter(element => element.id !== this.slideToDelete.id)
      }

      this.deleteSlideDialogState = false
    }
  }
}
</script>

<style lang="scss">
.global-actions {
  margin-bottom: 1rem;
}
.action {
  margin-right: 0.5rem;
}
.v-chip-light-bg {
  margin-right: 0.5rem;
}
.clear {
  clear: both;
}
</style>
